import classnames from 'classnames'

import { scaleStepFormat } from '../constants/settings'
import MapStore from '../store/mapStore'
import Treemap from '../treemap'
import { MapTypeId } from '../types'

interface LegendProps {
  treemap: Treemap
  className?: string
}

function Legend(props: LegendProps) {
  const colorScale = props.treemap.colorScale

  if (!colorScale) {
    return null
  }

  var boxover =
    'cssbody=[tooltip_bdy] cssheader=[tooltip_hdr] header=[' +
    props.treemap.scale.name +
    "] body=[<table width=300><tr><td class='tooltip_tab'>" +
    props.treemap.scale.tooltip +
    '</td></tr></table>] offsety=[-70] delay=[500]'

  const [scaleMin, scaleMax] = props.treemap.getScaleMinMax()
  var steps = [scaleMin]
  var diff = scaleMax - scaleMin
  if (diff > 0) {
    var step = diff / 6
    for (var i = 1; i < 7; i++) {
      steps.push(Math.round((scaleMin + step * i) * 10) / 10)
    }
  }

  if (props.treemap.scale.id === '_rec') {
    steps = [5, 4, 3, 2, 1]
  } else if (props.treemap.scale.id === '_earndate') {
    steps = [-30, -15, -1, 1, 15, 30]
  }

  // TODO some widths
  var stepWidth = ['_rec', '_earndate'].indexOf(props.treemap.scale.id) !== -1 ? 100 : 50
  var format = scaleStepFormat[props.treemap.scale.id]
  return (
    <div className={classnames('flex justify-between', props.className)}>
      {![MapTypeId.Portfolio, MapTypeId.ETFHoldings, MapTypeId.ManagersAndFunds].includes(props.treemap.type) && (
        <div className="info w-full">
          Use mouse wheel to zoom in and out. Drag zoomed map to pan it.
          <br />
          Double&#x2011;click a ticker to display detailed information in a new window.
          <br />
          {!MapStore.isWidget() &&
            'Hover mouse cursor over a ticker to see its main competitors in a stacked view with a 3-month history graph.'}
        </div>
      )}
      <div className="ml-auto mr-0 flex space-x-px whitespace-nowrap" data-boxover={boxover}>
        {steps.map((step, index) => (
          <div
            key={`${step}-${index}`}
            className="flex h-6 items-center justify-center px-2 text-2xs text-white font-normal"
            style={{
              background: colorScale(step),
              minWidth: stepWidth,
              textShadow: '0 1px 0 rgba(0, 0, 0, 0.25)',
            }}
          >
            {props.treemap.formatValue(step.toString(), [scaleMin, scaleMax], format)}
          </div>
        ))}
      </div>
    </div>
  )
}

export default Legend
